<template>
  <div>
    <Loading v-bind:showLoadScreen="screenLoading" />
    <!-- <Error v-bind:errors="errors" /> -->

    <div v-if="$store.state.sytUser.type === 'VENDOR'">
      <Border v-bind:showLoading="checkAccountSettings">
        <div v-if="!showAccount" class="lineItem item3">
          Account settings
          <img src="../assets/down.png" class="imgIcon right smaller" @click="toggleAccount()" />
        </div>
        <div v-if="showAccount">
          <div class="lineItem item3">
            Account settings
            <img src="../assets/up.png" class="imgIcon right smaller" @click="toggleAccount()" />
          </div>
          <Border v-if="logRequestErrors.length > 0" v-bind:errors="logRequestErrors">
          </Border>

          <div v-if="accountDetails.accountType == 'INIT'">
            <br />Checking account...
          </div>

          <div v-if="!(accountDetails.accountType == 'INIT')">
            <Border>
              <div v-if="!(accountDetails.accountType == 'INIT') &&
      !(accountDetails.accountType == 'FREE')
      ">
                <div class="centerOfRow lineItem item3">Free account</div>
                <div class="item">
                  <div class="lineItem">This is the default account if you don't select any of the other account
                    options. Feel free to use this option to explore the application and experiment with the different
                    application features available to you.
                  </div>
                </div>
                <div class="item">
                  <div class="lineItem">Max rewards you can create - 2</div>
                  <div class="lineItem">Max amount of voucher scans - 500 (every month)</div>
                </div>
                <!-- <div>
                  <button @click="switchToFree">Activate</button>
                </div> -->

                <!-- <div v-if="switchFromAnyToFree">
                  <Border>
                    <div class="item">
                      When you want to switch to the Free account, you have to click on the "Manage" button in your
                      current account
                      selection and cancel the account. This will reslut in your account being switched to "Free" when the
                      end of the
                      paid period has been reached
                    </div>
                    <div class="centerOfRow">
                      <button @click="switchFromAnyToFreeOk">ok</button>
                    </div>
                  </Border>
                </div> -->

              </div>
              <div v-if="accountDetails.accountType == 'FREE'">
                <div class="centerOfRow accountActive">
                  Free account (currently active)
                </div>
                <div class="item">
                  <div class="lineItem">
                    Rewards - {{ accountDetails.rewardCurrentCount }} from a max
                    of {{ accountDetails.rewardMaxCount }}
                  </div>
                  <!-- <div class="lineItem">
                    Delegate users -
                    {{ accountDetails.delegateCurrentCount }} from a max of
                    {{ accountDetails.delegateMaxCount }}
                  </div> -->
                  <div class="lineItem">
                    Voucher scans - {{ accountDetails.voucherCurrentCount }} from
                    a max of {{ accountDetails.voucherMaxCount }}
                  </div>
                </div>
              </div>
            </Border>

            <Border>
              <div v-if="!(accountDetails.accountType == 'INIT') &&
      !(accountDetails.accountType == 'BASIC')
      ">
                <div class="centerOfRow lineItem item3">Basic account - €5 per month</div>
                <div class="item">
                  <div class="lineItem">This account type gives you a bit more freedom to create more rewards with an
                    added
                    benefit of delegating scanning privileges to another person should you not be available.
                  </div>
                </div>
                <div class="item">
                  <div class="lineItem">Max rewards you can create - 4</div>
                  <div class="lineItem">Max amount of delegated users - 1</div>
                  <div class="lineItem">Max amount of voucher scans - 10 000 (every monthly billing cycle)</div>
                </div>
                <div class="right">
                  <button @click="switchToBasic">Activate</button>
                </div>
              </div>
              <div v-if="accountDetails.accountType == 'BASIC'">
                <div class="centerOfRow accountActive">
                  Basic account (currently active)
                </div>
                <div class="item">
                  <div class="lineItem">This account type gives you a bit more freedom to create more rewards with an
                    added
                    benefit of delegating scanning privileges to another person should you not be available.
                  </div>
                </div>
                <div class="item">
                  <div class="lineItem">
                    Rewards - {{ accountDetails.rewardCurrentCount }} from a max
                    of {{ accountDetails.rewardMaxCount }}
                  </div>
                  <div class="lineItem">
                    Delegate users -
                    {{ accountDetails.delegateCurrentCount }} from a max of
                    {{ accountDetails.delegateMaxCount }}
                  </div>
                  <div class="lineItem">
                    Voucher scans - {{ accountDetails.voucherCurrentCount }} from
                    a max of {{ accountDetails.voucherMaxCount }}
                  </div>
                </div>

                <div class="right">
                  <button @click="cancelBasicAccount">Cancel</button>
                </div>
                <div class="centerOfRow">
                  <h3 v-if="showConfirmCancelBasic">Are you sure?</h3>
                </div>
                <div class="centerOfRow">
                  <button v-if="showConfirmCancelBasic" @click="cancelConfirmed">
                    yes
                  </button>
                  <button v-if="showConfirmCancelBasic" @click="cancelBasicCancelled">
                    no
                  </button>
                </div>
              </div>
            </Border>

            <Border>
              <div v-if="!(accountDetails.accountType == 'INIT') &&
      !(accountDetails.accountType == 'STANDARD')
      ">
                <div class="centerOfRow lineItem item3">Standard account - €12 per month</div>
                <div class="item">
                  <div class="lineItem">This account type is particularly usefull for a small business and gives the
                    ability to
                    allocate more user delegates while maintaining a competative active rewards list.
                  </div>
                </div>
                <div class="item">
                  <div class="lineItem">Max rewards you can create - 8</div>
                  <div class="lineItem">Max amount of delegated users - 3</div>
                  <div class="lineItem">Max amount of voucher scans - 20 000 (every monthly billing cycle)</div>
                </div>
                <div class="right">
                  <button @click="switchToStandard">Activate</button>
                </div>
              </div>
              <div v-if="accountDetails.accountType == 'STANDARD'">
                <div class="centerOfRow accountActive">
                  Standard account (currently active)
                </div>
                <div class="item">
                  <div class="lineItem">This account type is particularly usefull for a small business and gives the
                    ability to
                    allocate more user delegates while maintaining a competative active rewards list.
                  </div>
                </div>
                <div class="item">
                  <div class="lineItem">This account type is particularly usefull for a small business and gives the
                    ability to
                    allocate more user delegates while maintaining a competative active rewards list.
                  </div>
                </div>
                <div class="item">
                  <div class="lineItem">
                    Rewards - {{ accountDetails.rewardCurrentCount }} from a max
                    of {{ accountDetails.rewardMaxCount }}
                  </div>
                  <div class="lineItem">
                    Delegate users -
                    {{ accountDetails.delegateCurrentCount }} from a max of
                    {{ accountDetails.delegateMaxCount }}
                  </div>
                  <div class="lineItem">
                    Voucher scans - {{ accountDetails.voucherCurrentCount }} from
                    a max of {{ accountDetails.voucherMaxCount }}
                  </div>
                </div>

                <div class="right">
                  <button @click="cancelStandardAccount">Cancel</button>
                </div>
                <div class="centerOfRow">
                  <h3 v-if="showConfirmCancelStandard">Are you sure?</h3>
                </div>
                <div class="centerOfRow">
                  <button v-if="showConfirmCancelStandard" @click="cancelConfirmed">
                    yes
                  </button>
                  <button v-if="showConfirmCancelStandard" @click="cancelStandardCancelled">
                    no
                  </button>
                </div>
              </div>
            </Border>

            <Border>
              <div v-if="!(accountDetails.accountType == 'INIT') &&
      !(accountDetails.accountType == 'COMPREHENSIVE')
      ">
                <div class="centerOfRow lineItem item3">Comprehensive account - €29 per month</div>
                <div class="item">
                  <div class="lineItem">An account type for use by a bigger business. Create many rewards and get your
                    team invloved by delegating scanning privilges to them.
                    If you need more rewards an/or delegated users than this, please contact us in the 'Talk to us'
                    section - we are here to help.
                  </div>
                </div>
                <div class="item">
                  <div class="lineItem">Max rewards you can create - 20</div>
                  <div class="lineItem">Max amount of delegated users - 10</div>
                  <div class="lineItem">Max amount of voucher scans - 500 000 (every monthly billing cycle)</div>
                </div>
                <div class="right">
                  <button @click="switchToComprehensive">Activate</button>
                </div>
              </div>
              <div v-if="accountDetails.accountType == 'COMPREHENSIVE'">
                <div class="centerOfRow accountActive">
                  Comprehensive account (currently active)
                </div>
                <div class="item">
                  <div class="lineItem">An account type for use by a bigger business. Create many rewards and get your
                    team invloved by delegating scanning privilges to them.
                    If you need more rewards an/or delegated users than this, please contact us in the 'Talk to us'
                    section - we are here to help.
                  </div>
                </div>
                <div class="item">
                  <div class="lineItem">
                    Rewards - {{ accountDetails.rewardCurrentCount }} from a max
                    of {{ accountDetails.rewardMaxCount }}
                  </div>
                  <div class="lineItem">
                    Delegate users -
                    {{ accountDetails.delegateCurrentCount }} from a max of
                    {{ accountDetails.delegateMaxCount }}
                  </div>
                  <div class="lineItem">
                    Voucher scans - {{ accountDetails.voucherCurrentCount }} from
                    a max of {{ accountDetails.voucherMaxCount }}
                  </div>
                </div>

                <div class="right">
                  <button @click="cancelComprehensiveAccount">Cancel</button>
                </div>
                <div class="centerOfRow">
                  <h3 v-if="showConfirmCancelComprehensive">Are you sure?</h3>
                </div>
                <div class="centerOfRow">
                  <button v-if="showConfirmCancelComprehensive" @click="cancelConfirmed">
                    yes
                  </button>
                  <button v-if="showConfirmCancelComprehensive" @click="cancelComprehensiveCancelled">
                    no
                  </button>
                </div>
              </div>

            </Border>
          </div>
        </div>
      </Border>
    </div>
    <Border>
      <div v-if="!showUserManagement" class="lineItem item3">
        User management
        <img src="../assets/down.png" class="imgIcon right smaller" @click="toggleUserManagement()" />
      </div>
      <div v-if="showUserManagement" class="lineItem item3">
        User management
        <img src="../assets/up.png" class="imgIcon right smaller" @click="toggleUserManagement()" />
      </div>
      <div v-if="showUserManagement" class="lineItem">
        <div v-if="$store.state.sytUser.type === 'VENDOR'">
          <Border v-bind:errors="delegateErrors">
            <div class="lineItem item1">
              Delegate or remove scanning privileges
            </div>
            <div class="item">
              As a vendor, you can delegate (or remove) scanning and reward
              issueing privilages to other users. The users in question must be registred on 'See you tomorrow' if you
              want to allocate scanning priviliges them.
              To delegate scanning privilages, you must enter the users' email address in the box below. If successfull,
              the allocated user
              will be able to scan rewards and redeem vouchers on your behalf.
            </div>

            <div class="lineItem">
              <input class="rcornersInput" v-model="sytUserToUpdate.assignUserAsVendor"
                placeholder="Email of delegate" />
              <div class="rightButton">
                <button @click="delegate()">Delegate</button>
              </div>
            </div>
            <div v-if="sytUserToUpdate.vendorDelegates &&
      sytUserToUpdate.vendorDelegates.length > 1
      ">
              <div class="item">
                <div class="lineItemHeader">Existing delegates:</div>
                <div v-for="vendorDelegate in sytUserToUpdate.vendorDelegates" v-bind:key="vendorDelegate">
                  <div class="item" v-if="vendorDelegate.length > 0">
                    {{ vendorDelegate }}
                    <img src="../assets/delete.png" class="imgIcon right smaller"
                      @click="removeDelegate(vendorDelegate)" />
                  </div>
                </div>
              </div>
            </div>
          </Border>
        </div>

        <div v-if="!showChangeToVendor && $store.state.sytUser.type === 'PATRON'">
          <Border v-bind:errors="vendorErrors">
            <div class="lineItem item1">Change to Vendor account</div>
            <div class="item">
              Your account type is currenly that of a customer. Changing to a Vendor account allows you to create
              rewards and scan QR codes.
              You would still have all the functionality of a customer with the added functionality of a Vendor. You can
              switch between a Customer and Vendor account
              at any stage.
            </div>
            <div class="rightButton">
              <button @click="changeToVendor()">Change</button>
            </div>
          </Border>
        </div>
        <div v-if="showChangeToVendor && $store.state.sytUser.type === 'PATRON'">
          <Border>
            <div class="item">
              All you need to change to a Vendor account is a business name.
              Having a Vendor account will allow you to create rewards and track
              the usage thereof. You can change back at any time to a normal
              account.
            </div>
            <p>
              <input class="rcornersInput" v-model="sytUserToUpdate.vendorName" placeholder="Business name" />
            </p>
            <div class="centerOfRow">
              <button @click="saveVendor">save</button>
              <button @click="cancelVendor">cancel</button>
            </div>
          </Border>
        </div>

        <div v-if="!showChangeToPatron && $store.state.sytUser.type === 'VENDOR'">
          <Border>
            <div class="lineItem item1">Change to customer account</div>
            <div class="item">
              Your account type is currenly that of a vendor. This allows you to create rewards and scan QR codes.
              If you do not want to use these elevated priviliges you can switch to a customer account. You can switch
              between a Vendor and Customer account
              at any stage.
            </div>
            <div class="rightButton">
              <button @click="changeToPatron()">Change</button>
            </div>
          </Border>
        </div>

        <div v-if="showChangeToPatron && $store.state.sytUser.type === 'VENDOR'">
          <Border v-bind:errors="patronErrors">
            <div class="item">
              When you change to a customer account, you will not be able to
              create, manage and scan rewards.
            </div>
            <div class="centerOfRow">
              <button @click="savePatron">change</button>
              <button @click="cancelPatron">cancel</button>
            </div>
          </Border>
        </div>

        <Border v-bind:errors="logoutErrors">
          <div class="lineItem item1">Log out</div>
          <div class="item">
            You are logged in as {{ $store.state.sytUser.name }} using
            {{ $store.state.sytUser.userName }}. To log out of this account please click the logout button.
            After logout, you will be redirected to the Home page where you can login again.
          </div>
          <div class="rightButton">
            <button @click="logout">Log out</button>
          </div>
        </Border>

        <div v-if="!showDeleteUserConfirm">
          <Border v-bind:errors="deleteErrors">
            <div class="lineItem item1">Delete user</div>
            <div class="item">
              You are logged in as {{ $store.state.sytUser.name }} using
              {{ $store.state.sytUser.userName }}. By clicking the delete button, this user will be deleted from See you
              tomorrow.
              This operation cannot be undone and although you can sign up with the same user again, all your history
              will be lost.
            </div>
            <div class="rightButton">
              <button @click="deleteUser">Delete</button>
            </div>
          </Border>
        </div>
        <div v-if="showDeleteUserConfirm">
          <Border>
            <div class="item">
              Are you sure you wan to the delete your user account?
              <p><strong>This operation cannot be undone!</strong></p>
            </div>
            <div class="centerOfRow">
              <button @click="deleteUserConfirm">delete</button>
              <button @click="deleteUserCancel">cancel</button>
            </div>
          </Border>
        </div>







      </div>
    </Border>

    <div v-if="$store.state.sytUser.type === 'VENDOR'">
      <Border>
        <div v-if="!showRewards" class="lineItem item3">
          Create and change rewards
          <img src="../assets/edit4.png" class="imgIcon right smaller" @click="gotoRewards()" />
        </div>
      </Border>
    </div>

    <Border>
      <div v-if="!showTalk" class="lineItem item3">
        Talk to us
        <img src="../assets/down.png" class="imgIcon right smaller" @click="toggleTalk()" />
      </div>
      <div v-if="showTalk" class="lineItem">
        <div class="lineItem item3">
          Talk to us
          <img src="../assets/up.png" class="imgIcon right smaller" @click="toggleTalk()" />
        </div>
        <div class="lineItem item1">Have you say</div>
        <div class="item">
          Please use the area below to send us a message. We will respond to your message using the email address of
          your logged in account.
        </div>
        <Border v-bind:errors="logRequestErrors">
          <textarea v-model="logRequestDetails.requestBody" placeholder="What's on your mind"
            class="requestStyleTextArea"></textarea>
          <div style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 100%;
            ">
            <div v-if="!logSaved" class="rightButton">
              <button @click="logRequest">Send</button>
            </div>
            <div v-if="logSaved">
              <label>Message sent!</label>
            </div>
          </div>
        </Border>
      </div>
    </Border>

    <Border>
      <div v-if="!showTerms" class="lineItem item3">
        Terms of use
        <img src="../assets/down.png" class="imgIcon right smaller" @click="toggleTerms()" />
      </div>
      <div v-if="showTerms" class="lineItem">
        <div class="lineItem item3">
          Terms of use
          <img src="../assets/up.png" class="imgIcon right smaller" @click="toggleTerms()" />
        </div>
        <Border v-bind:errors="logRequestErrors">
          <div style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 100%;
            ">
            <Terms></Terms>
          </div>
        </Border>
      </div>
    </Border>

    <Border>
      <div v-if="!showPrivacy" class="lineItem item3">
        Privacy policy
        <img src="../assets/down.png" class="imgIcon right smaller" @click="togglePrivacy()" />
      </div>
      <div v-if="showPrivacy" class="lineItem">
        <div class="lineItem item3">
          Privacy policy
          <img src="../assets/up.png" class="imgIcon right smaller" @click="togglePrivacy()" />
        </div>
        <Border v-bind:errors="logRequestErrors">
          <div style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 100%;
            ">
            <Privacy></Privacy>
          </div>
        </Border>
      </div>
    </Border>
  </div>
</template>

<script>
import Border from "@/components/Border.vue";
// import StripeModal from "@/components/StripeModal.vue";
import Terms from "@/components/Terms.vue";
import Privacy from "@/components/Privacy.vue";
import Loading from "@/components/Loading.vue";
import Comms from "../shared";
export default {
  name: "Admin",
  components: {
    Border,
    Terms,
    Privacy,
    Loading,
    // StripeModal,
  },
  data: function () {
    return {
      delegateErrors: [],
      logRequestErrors: [],
      vendorErrors: [],
      patronErrors: [],
      logoutErrors: [],
      deleteErrors: [],
      errors: [],
      showChangeToVendor: false,
      switchFromAnyToFree: false,
      checkAccountSettings: false,
      showChangeToPatron: false,
      screenLoading: false,
      showUserManagement: false,
      showDeleteUserConfirm: false,
      showRewards: false,
      showTalk: false,
      showTerms: false,
      showPrivacy: false,
      showAccount: false,
      showFreeAccountModal: false,
      showConfirmCancelBasic: false,
      showConfirmCancelStandard: false,
      showConfirmCancelComprehensive: false,
      logSaved: false,
      logRequestDetails: {
        requestBody: "",
        userId: "",
      },
      accountDetails: {
        accountType: "INIT",
      },
      switchAccount: {
        username: "",
        accountType: "",
      },
      sytUserToUpdate: {
        userID: "",
        userName: "",
        type: "",
        status: "",
        vendorName: "",
        assignUserAsVendor: "",
        operatingAsVendor: "",// use this var to pass the person to delegate to the backend.
        vendorDelegates: [],
      },
    };
  },
  methods: {
    gotoRewards() {
      if (this.$store.state.sytUser.type === "VENDOR") {
        this.$router.push({ name: "Rewards" });
      }
    },
    cancelConfirmed() {
      this.screenLoading = true;
      this.showAccount = false;
      this.logRequestErrors = [];
      this.switchAccount.username = this.$store.state.sytUser.userName;
      this.switchAccount.accountType = "";
      Comms.sendToServer(
        "POST",
        "cancelAccount",
        this.switchAccount,
        this.logRequestErrors
      ).then(() => {
        // wait for stripe to complete, then reload account
        this.screenLoading = true;
        setTimeout(() => {
          this.toggleAccount();
          this.screenLoading = false;
        }, 3000);
      });
    },
    cancelBasicCancelled() {
      this.showConfirmCancelBasic = false;
    },
    cancelBasicAccount() {
      this.showConfirmCancelBasic = true;
    },
    cancelStandardCancelled() {
      this.showConfirmCancelStandard = false;
    },
    cancelStandardAccount() {
      this.showConfirmCancelStandard = true;
    },
    cancelComprehensiveCancelled() {
      this.showConfirmCancelComprehensive = false;
    },
    cancelComprehensiveAccount() {
      this.showConfirmCancelComprehensive = true;
    },
    manageAccount() {
      this.screenLoading = true;
      this.showAccount = false;
      this.logRequestErrors = [];
      this.switchAccount.username = this.$store.state.sytUser.userName;
      this.switchAccount.accountType = "";
      Comms.sendToServer(
        "POST",
        "customerPortal",
        this.switchAccount,
        this.logRequestErrors
      ).then((response) => {
        // console.log(response);
        if (response && response.status == 200) {
          // this will redirect to swipe now
          let location = JSON.parse(response.data);
          window.location.assign(location.location); // todo - do not show the screen until redirect happened
        } else {
          this.screenLoading = false;
        }
      });
    },
    switchFromAnyToFreeOk() {
      this.switchFromAnyToFree = false;
    },
    switchToFree() {
      this.switchFromAnyToFree = true;
      // this.screenLoading = true;
      // this.showAccount = false;
      // this.accountDetails.accountType = "INIT";
      // this.logRequestErrors = [];
      // this.switchAccount.username = this.$store.state.sytUser.userName;
      // this.switchAccount.accountType = "FREE";
      // console.log("this.switchAccount:" + this.switchAccount.username);
      // Comms.sendToServer(
      //   "POST",
      //   "switchAccountType",
      //   this.switchAccount,
      //   this.logRequestErrors
      // ).then((response) => {
      //   console.log(response);
      //   if (response && response.status == 200) {
      //     this.accountDetails = JSON.parse(response.data);
      //     console.log(
      //       "this.accountDetails.accountType: " +
      //       this.accountDetails.accountType
      //     );
      //   }
      //   this.screenLoading = false;
      //   this.showAccount = true;
      // });
    },
    switchToBasic() {
      this.screenLoading = true;
      this.showAccount = false;
      this.accountDetails.accountType = "INIT";
      this.logRequestErrors = [];
      this.switchAccount.username = this.$store.state.sytUser.userName;
      this.switchAccount.accountType = "BASIC";
      // console.log("this.switchAccount:" + this.switchAccount.username);
      Comms.sendToServer(
        "POST",
        "switchAccountType",
        this.switchAccount,
        this.logRequestErrors
      ).then((response) => {
        // console.log(response);
        if (response && response.status == 200) {
          // this will redirect to swipe now
          let location = JSON.parse(response.data);
          // this.showFreeAccountModal = true;
          window.location.assign(location.location); // todo - do not show the screen until redirect happened
        } else {
          this.screenLoading = false;
          // this.showAccount = true;
        }
      });
    },
    switchToStandard() {
      this.screenLoading = true;
      this.showAccount = false;
      this.accountDetails.accountType = "INIT";
      this.logRequestErrors = [];
      this.switchAccount.username = this.$store.state.sytUser.userName;
      this.switchAccount.accountType = "STANDARD";
      // console.log("this.switchAccount:" + this.switchAccount.username);
      Comms.sendToServer(
        "POST",
        "switchAccountType",
        this.switchAccount,
        this.logRequestErrors
      ).then((response) => {
        // console.log(response);
        if (response && response.status == 200) {
          // this will redirect to swipe now
          let location = JSON.parse(response.data);
          // this.showFreeAccountModal = true;
          window.location.assign(location.location); // todo - do not show the screen until redirect happened
        } else {
          this.screenLoading = false;
          // this.showAccount = true;
        }
      });
    },
    switchToComprehensive() {
      this.screenLoading = true;
      this.showAccount = false;
      this.accountDetails.accountType = "INIT";
      this.logRequestErrors = [];
      this.switchAccount.username = this.$store.state.sytUser.userName;
      this.switchAccount.accountType = "COMPREHENSIVE";
      // console.log("this.switchAccount:" + this.switchAccount.username);
      Comms.sendToServer(
        "POST",
        "switchAccountType",
        this.switchAccount,
        this.logRequestErrors
      ).then((response) => {
        // console.log(response);
        if (response && response.status == 200) {
          // this will redirect to swipe now
          let location = JSON.parse(response.data);
          // this.showFreeAccountModal = true;
          window.location.assign(location.location); // todo - do not show the screen until redirect happened
        } else {
          this.screenLoading = false;
          // this.showAccount = true;
        }
      });
    },
    toggleTalk() {
      if (this.showTalk) {
        this.showTalk = false;
      } else {
        this.showTalk = true;
        this.logSaved = false;
      }
    },
    toggleTerms() {
      if (this.showTerms) {
        this.showTerms = false;
      } else {
        this.showTerms = true;
      }
    },
    togglePrivacy() {
      if (this.showPrivacy) {
        this.showPrivacy = false;
      } else {
        this.showPrivacy = true;
      }
    },
    toggleAccount() {
      this.logRequestErrors = [];
      // console.log("showAccount:" + this.showAccount);
      if (this.showAccount) {
        this.showAccount = false;
      } else {
        this.showAccount = true;
        this.checkAccountSettings = true;
        // check the current account type and bill
        Comms.sendToServer(
          "POST",
          "getAccountType",
          this.sytUserToUpdate,
          this.logRequestErrors
        ).then((response) => {
          // console.log(response);
          if (response && response.status == 200) {
            this.accountDetails = JSON.parse(response.data);
          }
          this.checkAccountSettings = false;
        });
      }
    },
    toggleUserManagement() {
      if (this.showUserManagement) {
        this.showUserManagement = false;
      } else {
        this.showUserManagement = true;
      }
    },
    logRequest() {
      this.screenLoading = true;
      this.logRequestErrors = [];
      this.logRequestDetails.userId = this.$store.state.sytUser.userID;
      Comms.sendToServer(
        "POST",
        "logRequest",
        this.logRequestDetails,
        this.logRequestErrors
      ).then((response) => {
        // console.log(response);
        if (response && response.status == 204) {
          this.logRequestDetails.requestBody = "";
          this.logSaved = true;
        }
        this.screenLoading = false;
      });
    },
    delegate() {
      this.screenLoading = true;
      this.delegateErrors = [];
      this.sytUserToUpdate.userID = this.$store.state.sytUser.userID;
      this.sytUserToUpdate.userName = this.$store.state.sytUser.userName;
      this.sytUserToUpdate.operatingAsVendor = this.sytUserToUpdate.assignUserAsVendor;
      Comms.sendToServer(
        "POST",
        "delegateUser",
        this.sytUserToUpdate,
        this.delegateErrors
      ).then((response) => {
        // console.log(response);
        if (response && response.status == 200) {
          this.sytUserToUpdate = JSON.parse(response.data);
          this.$store.commit("updateUser", this.sytUserToUpdate);
          this.sytUserToUpdate.assignUserAsVendor = "";
        }
        this.screenLoading = false;
      });
    },
    removeDelegate(delegateToRemove) {
      this.screenLoading = true;
      this.delegateErrors = [];
      this.sytUserToUpdate.assignUserAsVendor = delegateToRemove;
      Comms.sendToServer(
        "POST",
        "removeDelegatedUser",
        this.sytUserToUpdate,
        this.delegateErrors
      ).then((response) => {
        // console.log(response);
        if (response && response.status == 200) {
          this.sytUserToUpdate = JSON.parse(response.data);
          this.$store.commit("updateUser", this.sytUserToUpdate);
        }
        this.screenLoading = false;
      });
    },
    changeToVendor() {
      if (this.$store.state.sytUser.type === "PATRON") {
        this.showChangeToVendor = true;
      }
    },
    cancelVendor() {
      if (this.$store.state.sytUser.type === "PATRON") {
        this.showChangeToVendor = false;
      }
    },
    saveVendor() {
      if (
        this.$store.state.authenticated &&
        this.$store.state.sytUser.type === "PATRON"
      ) {
        this.screenLoading = true;
        this.sytUserToUpdate.userID = this.$store.state.sytUser.userID;
        this.sytUserToUpdate.status = this.$store.state.sytUser.status;
        this.sytUserToUpdate.userName = this.$store.state.sytUser.userName;
        this.sytUserToUpdate.name = this.$store.state.sytUser.name;
        this.sytUserToUpdate.type = "VENDOR";
        Comms.sendToServer(
          "POST",
          "updateUser",
          this.sytUserToUpdate,
          this.vendorErrors
        ).then((response) => {
          // console.log(response);
          if (response.status == 200) {
            this.$store.commit("updateUserType", "VENDOR");
            this.$store.commit(
              "updateUserVendorName",
              this.sytUserToUpdate.vendorName
            );
            this.showChangeToVendor = false;
          }
          this.screenLoading = false;
        });
      }
    },
    changeToPatron() {
      if (this.$store.state.sytUser.type === "VENDOR") {
        this.showChangeToPatron = true;
      }
    },
    savePatron() {
      if (
        this.$store.state.authenticated &&
        this.$store.state.sytUser.type === "VENDOR"
      ) {
        this.screenLoading = true;

        this.sytUserToUpdate.userID = this.$store.state.sytUser.userID;
        this.sytUserToUpdate.status = this.$store.state.sytUser.status;
        this.sytUserToUpdate.userName = this.$store.state.sytUser.userName;
        this.sytUserToUpdate.name = this.$store.state.sytUser.name;
        this.sytUserToUpdate.type = "PATRON";
        this.sytUserToUpdate.vendorName = "";

        Comms.sendToServer(
          "POST",
          "updateUser",
          this.sytUserToUpdate,
          this.patronErrors
        ).then((response) => {
          // console.log(response);
          if (response.status == 200) {
            this.$store.commit("updateUserType", "PATRON");
            this.$store.commit("updateUserVendorName", "");
            this.showChangeToPatron = false;
          }
          this.screenLoading = false;
        });
      }
    },
    cancelPatron() {
      if (this.$store.state.sytUser.type === "VENDOR") {
        this.showChangeToPatron = false;
      }
    },
    async logout() {
      this.screenLoading = true;
      try {
        await this.$sytAuth.signOut();
        this.$store.commit("setAuthStatus", false);
        this.$router.push({ name: "Home" });
      } catch (error) {
        this.logoutErrors.push("Failed to logout. Please try again");
      }
      this.screenLoading = false;
    },
    deleteUser() {
      this.showDeleteUserConfirm = true;
    },
    deleteUserConfirm() {
      this.screenLoading = true;
      this.showDeleteUserConfirm = false;
      // call the backend and then logout the user
      Comms.sendToServer(
        "POST",
        "deleteUser",
        this.$store.state.sytUser,
        this.delegateErrors
      ).then((response) => {
        console.log(response);
        if (response && response.status == 204) {
          this.logout();
        }
        this.screenLoading = false;
      });
      
    },
    deleteUserCancel() {
      this.showDeleteUserConfirm = false;
    },
  },
  mounted: function () {
    if (!this.$store.state.authenticated) {
      this.$router.push({ name: "Home" });
    } else {
      this.sytUserToUpdate = this.$store.state.sytUser;
      this.sytUserToUpdate.assignUserAsVendor = "";
    }
  },
};
</script>

<style scoped>
label {
  display: inline-block;
  text-align: right;
  font-size: 1.3em;
}

.requestStyle {
  background-color: rgba(251, 251, 251, 0.7);
  padding: 5px;
  text-align: left;
  border-radius: 6px;
  border: solid 1px silver;
  color: rgba(7, 63, 246, 0.753);
  margin: 5px;
  font-size: 1.1em;
}

.requestStyleTextArea {
  background-color: rgba(251, 251, 251, 0.7);
  padding: 5px;
  text-align: left;
  border-radius: 6px;
  border: solid 1px silver;
  color: rgba(7, 63, 246, 0.753);
  margin: 5px;
  height: auto;
  min-height: 90px;
  width: 90%;
  font-size: 1.3em;
  /* resize: none; */
}

.boldFont {
  font-weight: bold;
}

.accountActive {
  font-weight: bold;
  /* color: rgb(247, 161, 48); */
  color: rgb(253, 75, 5);
}
</style>
